<template>
  <div class="three-d-page">
    <Preloader v-if="isLoading"/>
    <div class="model-container">
      <div id="viewer" class="viewer"></div>
    </div>
    <div class="switch-container">
      <div v-for="map in mapNames" :key="map.id"
           :class="['switch', {'active': activeButton === map.id}]"
           @click="triggerChange(map.id)">
        <!-- You can display the label or anything here -->
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { EXRLoader } from "three/examples/jsm/loaders/EXRLoader.js";

const assetsPath = "assets/";
const mapPath = "assets/maps/";
import Preloader from "@/components/Preloader.vue";

//import objectsData from "@/assets/mtshirt.json";// assert { type: "json" };
//console.log(objectsData);

export default {
  name: "ThreeDView",
  components:{
    Preloader,
  },
  data() {
    return {
      isLoading: false,
      threeLoader: null,
      hasObject: false,
      objectsData: null,
      defaultView: {
        rotateLeft: 0,
        rotateRight: 0,
        rotateUp: 0,
        rotateDown: 0,
        zoomIn: 0,
        zoomOut: 0,
      },
      objectReferences: {},
      objectSettings: {},
      isFullscreenMode: false,
      animated: null,
      objLoaded: 0,
      objCount: 0,
      scene: null,
      camera: null,
      controls: null,
      newEnvMap: null,
      renderer: null,
      materials: [],
      mapNames: [
      { id: 1, name: 'beerCan.jpg', label: 'Beer Can' },
      { id: 2, name: 'clouds.jpg', label: 'Clouds' },
      { id: 3, name: 'baloons.jpg', label: 'Balloons' },
      { id: 4, name: 'flowers.jpg', label: 'Flowers' }
      ],
      activeButton: 1,
    };
  },
  destroyed() {
    console.log("ThreeD View destroyed");
  },
  beforeDestroy() {
    console.log("beforeDestroy..");
    window.removeEventListener("resize", this.onWindowResize, false);
    window.removeEventListener(
      "webkitfullscreenchange",
      this.fullScreenOff,
      false
    );
    window.removeEventListener(
      "mozfullscreenchange",
      this.fullScreenOff,
      false
    );
    window.removeEventListener("fullscreenchange", this.fullScreenOff, false);
    window.removeEventListener("MSFullscreenChange", this.fullScreenOff, false);
    this.clearScene();
  },
  mounted() {
    this.someMethod().then(() => {
      //this.loadObjectData();
      this.initScene();
    });
    //this.initScene();
  },
  methods: {
    clearScene(){
      cancelAnimationFrame(this.animate);
      // this.animate = undefined;
      // this.animated = null;
      if(!this.hasObject){
        return;
      }
      for (var i = this.scene.children.length - 1; i >= 0; i--) {
        let obj = this.scene.children[i];
        this.scene.remove(obj);
      }
      // this.scene = null;
      // this.camera = null;
      // this.controls = null;
      // this.newEnvMap = null;
      // this.renderer = null;
      // this.materials = [];
      this.objectsData = {
        "sceneSettings":{},
      };
      this.defaultView = {
        rotateLeft: 0,
        rotateRight: 0,
        rotateUp: 0,
        rotateDown: 0,
        zoomIn: 0,
        zoomOut: 0
      };
      this.objCount = 0;
      this.objLoaded = 0;
      this.threeLoader = null;
      return;
    },
    async someMethod() {
      const baseUrl = this.$env.threeD;
      console.log(baseUrl);
      this.objectsData = await this.axios.get(`${ baseUrl }/fordTransit.json`);
      this.objectsData = this.objectsData.data;
      console.log(this.objectsData);
    },
    async loopThroughtObjects(objData){
      for (const obj of objData) {
        const result = await this.loadObj(obj);
        console.log(result);
      }
    },
    loadObjectData() {
      this.defaultView = this.objectsData.defaultView;
      if (this.objectsData.defaultView) {
        this.defaultView = this.objectsData.defaultView;
      }
      if (this.objectsData.objects) {
        this.hasObject = true;
        this.objCount = this.objectsData.objects.length;
        this.loopThroughtObjects(this.objectsData.objects);
        // _.forEach(this.objectsData.objects, (value) => {
        //   console.log("found an object");
        //   try {
        //     loadObj(value);
        //   } catch (error) {
        //     console.log("could not load object");
        //   }
        // });
      } else {
        console.log("no object found");
      }

      if (!this.animated) {
        this.animated = true;
        this.animate();
      }
    },
    triggerChange(mapId) {
    const map = this.mapNames.find(m => m.id === mapId);
      if (map) {
        console.log("triggerChange:", map.name);
        this.changeMainMapForObject(`${mapPath}${map.name}`, 'fordTransitBody.glb');
        this.activeButton = map.id; // Set the active button ID
      }
    },
    //-----------------------------------------------
    // async changeMainMapForObject(newMapPath, objectName) {
    //   const mapLoader = new THREE.TextureLoader();
    //   let newMap = await new Promise((resolve, reject) => {
    //     mapLoader.load(newMapPath, resolve, undefined, reject);
    //   });

    //   // Find the object using its name
    //   let object = this.objectReferences[objectName];
    //   if (object) {
    //     object.traverse((child) => {
    //       if (child.isMesh) {
    //         if (child.material.map) {
    //           child.material.map.dispose(); // Dispose the old main texture
    //         }
    //         child.material.map = newMap;
    //         child.material.needsUpdate = true;
    //       }
    //     });
    //   }
    // },
    async changeMainMapForObject(newMapPath, objectName) {
  const mapLoader = new THREE.TextureLoader();

  // Retrieve object settings
  const objVars = this.objectSettings[objectName];

  if (!objVars) {
    console.error("Object settings not found for: " + objectName);
    return;
  }

  let newMap = await new Promise((resolve, reject) => {
    mapLoader.load(newMapPath, resolve, undefined, reject);
  });

  // Apply map settings
  newMap.encoding = THREE.sRGBEncoding; // Default, adjust if needed
  newMap.anisotropy = objVars.objMap.anisotropy || 1;
  if (objVars.objMap.wrapS) {
    newMap.wrapS = objVars.objMap.wrapS;
  }
  if (objVars.objMap.wrapT) {
    newMap.wrapT = objVars.objMap.wrapT;
  }

  // Find the object using its name and update its material
  let object = this.objectReferences[objectName];
  if (object) {
    object.traverse((child) => {
      if (child.isMesh) {
        if (child.material.map) {
          child.material.map.dispose(); // Dispose the old main texture
        }
        child.material.map = newMap;
        child.material.needsUpdate = true;
        // Apply additional material properties if necessary
      }
    });
  }
},
    //-----------------------------------------------
    loadObj(objVars) {
      let that = this;
      const matSetter = new THREE.MeshStandardMaterial();
      const mapLoader = new THREE.TextureLoader();
      const loader = new GLTFLoader(that.threeLoader);

      loader.setPath(`${assetsPath}/` + objVars.path);
      loader.load(objVars.name, function (glb) {
        //Options
        if (objVars.side == "FrontSide") {
          matSetter.side = THREE.FrontSide;
        } else if (objVars.side == "BackSide") {
          matSetter.side = THREE.BackSide;
        } else {
          matSetter.side = THREE.DoubleSide;
        }
        if (objVars.objMap) {
          if (objVars.objMap.type == "custom") {
            let tmpMAP = mapLoader.load(`${mapPath}` + objVars.objMap.map);
            tmpMAP.encoding = THREE.sRGBEncoding;
            matSetter.map = tmpMAP;
            matSetter.map.anisotropy = objVars.objMap.anisotropy;
          } else if (objVars.objMap.type == "artwork") {
            let tmpMAP = mapLoader.load(`${mapPath}` + objVars.objMap.map);
            tmpMAP.encoding = THREE.sRGBEncoding;
            matSetter.map = tmpMAP;
            if (objVars.objMap.isEmissive) {
              matSetter.emissiveMap = tmpMAP;
              matSetter.emissive = new THREE.Color(0xffffff);
              matSetter.emissiveIntensity = objVars.objMap.emissiveIntensity;
            }
            matSetter.map.anisotropy = objVars.objMap.anisotropy;
          } else {
            console.log("no map type found");
          }
        }

        if (objVars.alphaMap) {
          let tmpMAP = mapLoader.load(`${mapPath}` + objVars.alphaMap);
          matSetter.alphaMap = tmpMAP;
        }

        if (objVars.armMap) {
          let tmpMAP = mapLoader.load(`${mapPath}` + objVars.armMap);
          matSetter.aoMap =
            matSetter.roughnessMap =
            matSetter.metalnessMap =
              tmpMAP;
          matSetter.aoMap.wrapS = matSetter.aoMap.wrapT = THREE.RepeatWrapping;
          matSetter.roughnessMap.wrapS = matSetter.roughnessMap.wrapT =
            THREE.RepeatWrapping;
          matSetter.metalnessMap.wrapS = matSetter.metalnessMap.wrapT =
            THREE.RepeatWrapping;
          if (objVars.tiles) {
            console.log("did set tiles");
            matSetter.aoMap.offset.set(0, 0);
            matSetter.roughnessMap.offset.set(0, 0);
            matSetter.metalnessMap.offset.set(0, 0);
            matSetter.aoMap.repeat.set(objVars.tiles[0], objVars.tiles[1]);
            matSetter.roughnessMap.repeat.set(
              objVars.tiles[0],
              objVars.tiles[1]
            );
            matSetter.metalnessMap.repeat.set(
              objVars.tiles[0],
              objVars.tiles[1]
            );
          }
        }

        if (objVars.normalMap) {
          let tmpMAP = mapLoader.load(`${mapPath}` + objVars.normalMap);
          matSetter.normalMap = tmpMAP;
          matSetter.normalMap.wrapS = matSetter.normalMap.wrapT =
            THREE.RepeatWrapping;
          if (objVars.normalScale) {
            matSetter.normalScale = new THREE.Vector2(
              objVars.normalScale[0],
              objVars.normalScale[1]
            );
          }
          if (objVars.tiles) {
            matSetter.normalMap.offset.set(0, 0);
            matSetter.normalMap.repeat.set(objVars.tiles[0], objVars.tiles[1]);
          }
        }

        if (objVars.bumpMap) {
          let tmpMAP = mapLoader.load(`${mapPath}` + objVars.bumpMap);
          matSetter.bumpMap = tmpMAP;
          matSetter.bumpMap.wrapS = matSetter.bumpMap.wrapT =
            THREE.RepeatWrapping;
          if (objVars.tiles) {
            matSetter.bumpMap.offset.set(0, 0);
            matSetter.bumpMap.repeat.set(objVars.tiles[0], objVars.tiles[1]);
          }
          if (objVars.bumpScale) {
            matSetter.bumpScale = parseInt(objVars.bumpScale);
          }
        }

        // matSetter.lightMapIntensity = 0;
        // matSetter.aoMapIntensity = 0;
        matSetter.toneMapped = objVars.toneMapped;
        if (objVars.basicMaterial) {
          matSetter.color = new THREE.Color(objVars.color);
          matSetter.roughness = objVars.roughness;
          matSetter.metalness = objVars.metalness;
        }
        matSetter.envMapIntensity = objVars.envMapIntensity;
        matSetter.transparent = objVars.transparent;
        matSetter.opacity = objVars.opacity;

        that.materials.push({ map: matSetter, ref: objVars.ref });
        //traverse---------------------------
        glb.scene.traverse(function (child) {
          if (child.isMesh) {
            child.material = that.materials[that.materials.length - 1].map;
            child.material.envMap = that.newEnvMap;
            child.material.needsUpdate = true;
            child.castShadow = objVars.castShadow;
            child.receiveShadow = objVars.receiveShadow;
          }
        });
        //end traverse-----------------------

        //Position, Rotation, Scale
        glb.scene.rotation.set(
          objVars.rotation.x,
          objVars.rotation.y,
          objVars.rotation.z
        );
        glb.scene.position.set(
          objVars.position.x,
          objVars.position.y,
          objVars.position.z
        );
        glb.scene.scale.set(objVars.scale.x, objVars.scale.y, objVars.scale.z);
        glb.scene.visible = objVars.visible;

        that.scene.add(glb.scene);
        that.objectReferences[objVars.name] = glb.scene;
        that.objectSettings[objVars.name] = objVars;
      });

      return new Promise((resolve, reject) => {
        resolve("done");
      });
    },
    //-----------------------------------------------
    initScene() {
      let that = this;
      console.log("did init");

      this.threeLoader = new THREE.LoadingManager();

      // Create Scene /////////////////////////////////////////////////////////////////////////////////////////////////////

      this.scene = new THREE.Scene();

      const viewerHeight = 1.3; // Controls Y position of camera and target

      // Renderers /////////////////////////////////////////////////////////////////////////////////////////////////////

      // WebGL Renderer properties

      const winW = document.getElementById("viewer").offsetWidth;
      const winH = document.getElementById("viewer").offsetHeight;

      this.renderer = new THREE.WebGLRenderer({
        antialias: true,
        preserveDrawingBuffer: true,
      });
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(winW, winH);
      const threeViewer = document.getElementById("viewer");
      threeViewer.appendChild(this.renderer.domElement);
      this.renderer.domElement.id = "3dviewer";

      this.renderer.outputEncoding = THREE.sRGBEncoding;
      this.renderer.physicallyCorrectLights = true;
      this.renderer.physicallyBasedShading = true;
      this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
      this.renderer.toneMappingExposure = 1;

      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMap.type = THREE.PCFShadowMap;

      // Camera and controls /////////////////////////////////////////////////////////////////////////////////////////////////////

      this.camera = new THREE.PerspectiveCamera(45, winW / winH, 0.1, 1000);
      this.camera.position.x = 0;
      this.camera.position.y = viewerHeight;
      this.camera.position.z = 6;

      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.target.set(0, viewerHeight, 0);

      this.controls.enableDamping = true;
      this.controls.dampingFactor = 0.1;

      this.controls.panSpeed = 0;
      this.controls.screenSpacePanning = false;

      this.controls.rotateSpeed = 0.3;

      this.controls.zoomSpeed = 2;
      this.controls.minDistance = 4;
      this.controls.maxDistance = 8;

      this.controls.minPolarAngle = 0.785398;
      this.controls.maxPolarAngle = 1.74533; // In Radians

      this.controls.saveState();

      this.controls.addEventListener(
        "end",
        function detectChange() {
          console.log("controls changed");
          //that.$emit("didtouch");
          that.controls.removeEventListener("end", detectChange);
        },
        { once: true }
      );

      // THREE.DefaultLoadingManager.onStart = function (
      //   url,
      //   itemsLoaded,
      //   itemsTotal
      // ) {
      //   console.log( 'Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
      //   that.isLoading = true;
      // };

      // const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
      // pmremGenerator.compileEquirectangularShader();

      // THREE.DefaultLoadingManager.onLoad = function () {

      //     pmremGenerator.dispose();
      //     this.isLoading = false;
      //     console.log("Loading complete!");
      //     setTimeout(() => {
      //      // this.setDefaultView();
      //     }, 1000);
        
      // };

      this.threeLoader.onStart = function (
        url,
        itemsLoaded,
        itemsTotal
      ) {
        //console.log( 'Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
        console.log('Loaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
        //that.loadingInfo = 'Loaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.';
        that.isLoading = true;
      };

      this.threeLoader.onLoad = function () {
        //console.log("objCount", that.objCount);
         if(that.objCount > 0){
          //pmremGenerator.dispose();
          //that.loadingInfo = "";
          that.isLoading = false;
          console.log("Loading complete!");
          console.log("animation start");
          if (!that.animated) {
            that.animated = true;
            that.animate();
          }
          setTimeout(() => {
            that.setDefaultView();
          }, 1000);
        }
      };

      new EXRLoader().load(
        `${mapPath}env.exr`,
        function (texture) {
          let exrCubeRenderTarget = pmremGenerator.fromEquirectangular(texture);
          //let exrBackground = exrCubeRenderTarget.texture;
          that.newEnvMap = exrCubeRenderTarget
            ? exrCubeRenderTarget.texture
            : null;

          texture.dispose();
        }
      );

      const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
      pmremGenerator.compileEquirectangularShader();

      this.scene.background = "#ffffff";//new THREE.Color(this.objectsData.sceneSettings.backgroundColor);

      // Lights /////////////////////////////////////////////////////////////////////////////////////////////////////

      const hemiLight = new THREE.HemisphereLight( 0xffffff, 0xffffff, 0.3 );
      hemiLight.color.setHSL( 0.6, 1, 0.6 );
      hemiLight.groundColor.setHSL( 0.095, 1, 0.75 );
      hemiLight.position.set( 0, 50, 0 ); 
      this.scene.add(hemiLight);

      const ambientLight = new THREE.AmbientLight(0xffffff, 0.3);
      this.scene.add(ambientLight);

      const shadowMapSize = 512 * 4;

      const centerLight = new THREE.PointLight(0xffffff, 20, 60, 2);
      centerLight.position.set(1.5, 6, 1);

      centerLight.castShadow = true;
      centerLight.shadow.bias = -0.0001;
      centerLight.shadow.radius = 30;
      centerLight.shadow.camera.near = 0.5;
      centerLight.shadow.camera.far = 100;
      centerLight.shadow.mapSize.width = shadowMapSize;
      centerLight.shadow.mapSize.height = shadowMapSize;

      this.scene.add(centerLight);

      const centerLight2 = new THREE.PointLight(0xffffff, 20, 60, 2);
      centerLight2.position.set(-1.5, 6, 0.5);

      centerLight2.castShadow = true;
      centerLight2.shadow.bias = -0.0001;
      centerLight2.shadow.radius = 30;
      centerLight2.shadow.camera.near = 0.5;
      centerLight2.shadow.camera.far = 100;
      centerLight2.shadow.mapSize.width = shadowMapSize;
      centerLight2.shadow.mapSize.height = shadowMapSize;

      this.scene.add(centerLight2);

      //Load 3D objects
      // Listeners /////////////////////////////////////////////////////////////////////////////////////////////////////

      this.loadObjectData();
      window.addEventListener("resize", this.onWindowResize, false);
      //fullScreenOff
      window.addEventListener(
        "webkitfullscreenchange",
        this.fullScreenOff,
        false
      );
      window.addEventListener("mozfullscreenchange", this.fullScreenOff, false);
      window.addEventListener("fullscreenchange", this.fullScreenOff, false);
      window.addEventListener("MSFullscreenChange", this.fullScreenOff, false);
    },
    onWindowResize() {
      if (this.isFullscreenMode) {
        var elem = document.getElementById("3dviewer");
        var sceneWidth = window.innerWidth;
        var sceneHeight = elem.offsetHeight;
        this.camera.aspect = sceneWidth / sceneHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(sceneWidth, sceneHeight);
      } else {
        const winW = document.getElementById("viewer").offsetWidth;
        const winH = document.getElementById("viewer").offsetHeight;
        this.camera.aspect = winW / winH;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(winW, winH);
      }
    },
    animate() {
      requestAnimationFrame(this.animate);
      this.controls.update();
      this.render();
    },
    render() {
      this.renderer.render(this.scene, this.camera);
    },
    setDefaultView() {
      console.log("setDefaultView");
      if (this.defaultView.rotateLeft > 0) {
        this.controls.doRotateLeft(this.defaultView.rotateLeft);
      }
      if (this.defaultView.rotateRight > 0) {
        this.controls.doRotateRight(this.defaultView.rotateRight);
      }
      if (this.defaultView.rotateUp > 0) {
        this.controls.doRotateUp(this.defaultView.rotateUp);
      }
      if (this.defaultView.rotateDown > 0) {
        this.controls.doRotateDown(this.defaultView.rotateDown);
      }
      if (this.defaultView.zoomIn > 0) {
        this.controls.doZoomIn(this.defaultView.zoomIn);
      }
      if (this.defaultView.zoomOut > 0) {
        this.controls.doZoomOut(this.defaultView.zoomOut);
      }
      //this.controls.doRotateLeft(0.4);
      //this.controls.saveState();
    },
    fullScreenOff() {
      if (
        document.webkitIsFullScreen ||
        document.mozFullScreen ||
        document.msFullscreenElement !== undefined
      ) {
        /* Run code when going to fs mode */
        console.log("isfulscreen");
      } else {
        this.isFullscreenMode = false;
        const winW = this.viewerWidth;
        const winH = this.viewerWidth;
        this.camera.aspect = winW / winH;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(winW, winH);
      }
    },
    fullScreen() {
      // let canvas = document.getElementById("3dviewer");
      this.viewerWidth = document.getElementById("viewer").offsetWidth;
      this.viewerWidth = document.getElementById("viewer").offsetHeight;

      var elem = document.getElementById("3dviewer");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }

      this.isFullscreenMode = true;
      //   this.camera.aspect = window.innerWidth / window.innerHeight;
      //   this.camera.updateProjectionMatrix();
      //   this.renderer.setSize(window.innerWidth, window.innerHeight);

      // canvas.requestFullscreen();
    },
  },
};
</script>
<style scoped>
.three-d-page {
  position: relative;
  overflow: hidden;
  background: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  /* background-image: repeating-linear-gradient(
      #fafafa 0 1px,
      transparent 1px 100%
    ),
    repeating-linear-gradient(90deg, #fafafa 0 1px, transparent 1px 100%);
  background-size: 10px 10px; */
}
.func-bt-wrapper {
  position: fixed;
  z-index: 39;
  top: 0px;
  left: 10px;
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}
/* overflow-y: scroll; */
.func-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: fit-content;
}
.proof-container {
  height: 100vh;
  /* width: 100vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.viewer {
  display: block;
  width: 100%;
  height: 800px;
  min-height: 800px;
  cursor: grab;
  border-radius: 60px;
  overflow: hidden;
}

.loading-info {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(54, 54, 54);
  font-size: 20px;
  text-align: center;
  z-index: 9999;
}

.three-d-page{
  border-radius: 60px;
  overflow: hidden;
}

.switch-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px; /* Adjust as needed */
    left: 0;
    right: 0;
  }

  .switch {
    width: 20px; /* Size of the dots */
    height: 20px;
    background-color: #ED6969;
    border-radius: 50%;
    margin: 0 10px; /* Space between dots */
    cursor: pointer;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); /* Optional: for better visibility */
  }

  .switch.active {
    outline: 2px solid white;
    box-shadow: 0 0 5px white; /* Optional: enhances visibility */
  }
</style>
