<template>
  <div class="form-popup-bg" :class="isFormVisible ? 'is-visible':''">
    <div class="form-container">
      <button @click="toggleForm()" id="btnCloseForm" class="close-button">
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="#002e46" viewBox="0 0 24 24">
              <path d="M18 6L6 18M6 6l12 12"/>
          </svg>
      </button>
      <div class="modal-title m-b-md">Contact Us</div>
      <form id="contactForm" @submit.prevent="sendMessage" v-if="form.status=='pending'">
        <div class="form-group">
          <label for="">Name</label>
          <input required v-model="form.name" type="text" class="form-control" />
        </div>
        <div class="form-group">
          <label for="">Company Name</label>
          <input v-model="form.company" class="form-control" type="text" />
        </div>
        <div class="form-group">
          <label for="">E-Mail Address</label>
          <input required v-model="form.email" class="form-control" type="text" />
        </div>
        <div class="form-group">
          <label for="">Phone Number</label>
          <input v-model="form.phone" class="form-control" type="text" />
        </div>
        <div class="form-group">
          <label for="">Message</label>
          <textarea required v-model="form.message" class="form-control" type="textarea" rows="4" cols="50"/>
        </div>
        <div class="form-buttons">
         <button class="form-bt">Send Message</button>
        </div>
      </form>
      <div v-if="form.status=='success'"><h1>Thank you for contacting us!</h1></div>
      <div v-if="form.status=='error'"><h1>There was an error sending your message. Please try again later.</h1></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactModal",
  data() {
    return {
      form: {
        name: "",
        email: "",
        message: "",
        company: "",
        phone: "",
        status: "pending",
        result: "",
      },
    };
  },
  methods: {
    toggleForm() {
      this.isFormVisible = !this.isFormVisible;
    },
    sendMessage(e) {
      //e.preventDefault();
      console.log("sending message...");
      this.axios.post("/sendmail.php", {
        token: this.$store.state.token,
        name: this.form.name,
        email: this.form.email,
        message: this.form.message,
        company: this.form.company,
        phone: this.form.phone,
        type: "contact",
      }).then((response) => {
        console.log(response);
        if (response.data.success) {
          this.form.status = "success";
        } else {
          this.form.status = "error";
        }
      }).catch((error) => {
        console.log(error);
      });
    },
    resetForm() {
      this.form = {
        name: "",
        email: "",
        message: "",
        company: "",
        phone: "",
        status: "pending",
        result: "",
      };
    },
  },
  computed: {
    isFormVisible: {
      get: function () {
        return this.$store.state.modals.contact;
      },
      // setter
      set: function () {
        this.$store.commit("toggleModal", "contact");
        return false;
      },
    },
  },
  watch: {
    isFormVisible: function (val) {
      if (val) {
        this.resetForm();
      }
    },
  },
};
</script>

<style scoped lang="scss">

.modal-title{
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-size: 4rem;
  color: #abb7c4;
  margin-bottom: 20px;
  text-transform: uppercase;
}
</style>
