<template>
  <div class="form-popup-bg" :class="isFormVisible ? 'is-visible':''">
    <div class="form-container">
      <button @click="toggleForm()" id="btnCloseForm" class="close-button">
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
              <path d="M18 6L6 18M6 6l12 12"/>
          </svg>
      </button>
      <div class="modal-title m-b-md">Feedback</div>
      <form id="feedbackForm" @submit.prevent="sendMessage" v-if="form.status=='pending'">
        <div class="form-group">
          <label for="">Name</label>
          <input required v-model="form.name" type="text" class="form-control" />
        </div>
        <div class="form-group">
          <label for="">E-Mail Address</label>
          <input required v-model="form.company" class="form-control" type="text" />
        </div>
        <div class="form-group">
          <label for="">Message</label>
          <textarea required v-model="form.message" class="form-control" type="textarea" rows="10" cols="50"/>
        </div>
        <button class="form-bt">Send Feedback</button>
      </form>
      <div v-if="form.status=='success'"><h1>Thank you for contacting us!</h1></div>
      <div v-if="form.status=='error'"><h1>There was an error sending your message. Please try again later.</h1></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeedbackModal",
  data() {
    return {
      form: {
        name: "",
        email: "",
        message: "",
        status: "pending",
        result: "",
      },
    };
  },
  methods: {
    toggleForm() {
      this.isFormVisible = !this.isFormVisible;
    },
    sendMessage(e) {
      //e.preventDefault();
      console.log("sending feedback...");
      this.axios.post("/sendmail.php", {
        token: this.$store.state.token,
        name: this.form.name,
        email: this.form.email,
        message: this.form.message,
        type: "feedback",
      }).then((response) => {
        console.log(response);
        if (response.data.success) {
          this.form.status = "success";
        } else {
          this.form.status = "error";
        }
      }).catch((error) => {
        console.log(error);
      });
    },
    resetForm() {
      this.form = {
        name: "",
        email: "",
        message: "",
        status: "pending",
        result: "",
      };
    },
  },
  computed: {
    isFormVisible: {
      get: function () {
        return this.$store.state.modals.feedback;
      },
      // setter
      set: function () {
        this.$store.commit("toggleModal", "feedback");
        return false;
      },
    },
  },
  watch: {
    isFormVisible: function (val) {
      if (val) {
        this.resetForm();
      }
    },
  },
};
</script>