import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/templates",
    name: "templates",
    component: () =>
      import(/* webpackChunkName: "templates" */ "../views/TemplatesView.vue"),
  },
  {
    path: "/policy",
    name: "policy",
    component: () =>
      import(/* webpackChunkName: "policy" */ "../views/PolicyView.vue"),
  },
  {
    path: "/project",
    name: "project",
    component: () =>
      import(/* webpackChunkName: "project" */ "../views/ProjectView.vue"),
  },
    // 404 Route
    {
      path: "*",
      name: "NotFound",
      component: () =>
        import(/* webpackChunkName: "notFound" */ "../views/NotFoundView.vue"),
    },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }else{
      return {
        x: 0,
        y: 0
      };
    }
  }
});

export default router;
