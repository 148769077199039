<template>
     <div class="footer">
      <div class="footer-logo">
        <img class="logo-icon" src="img/logo-icon.svg" alt="logo" />
      </div>
      <div class="row">
        <div class="col t-center">
          <router-link to="/policy">Privacy Policy</router-link>
          |
          <span class="clickable" @click="toggleForm">Contact Us</span>
        </div>
        <div class="col t-right">
          2024 &copy; Candova Inc. | All rights reserved.
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    toggleForm() {
      this.$store.commit("toggleModal", "contact");
    },
  },
};
</script>

<style scoped lang="scss">

.footer{
  font-family: "Roboto", sans-serif;
  position: relative;
  padding: 40px;
  background-color: #baffcd;
  color: #002e46;
  font-weight: 500;
  font-size: 2rem;
}

//footer floating logo
.footer-logo{
  position: absolute;
  bottom: 0;
  left: 10px;
  top: -90px;
  // transform: translateX(-50%);
  width: 100px;
  height: 100px;
}

.logo-icon{
  width: 180px;
  height: 180px;
}

//shrinc on mobile
@media (max-width: 768px) {
  .footer-logo{
    width: 50px;
    height: 50px;
    top: -50px;
  }
  .logo-icon{
    width: 100px;
    height: 100px;
  }
}

.footer a{
  color: #002e46;
  text-decoration: none;
  font-weight: 500;
  font-size: 2rem;
}

</style>
