import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    modals:{
      contact: false,
      feedback: false,
      trydemo: false,
    },
    token: null,
  },
  getters: {},
  mutations: {
    toggleModal(state, payload){
      console.log("toggleModal", payload);
      state.modals[payload] = !state.modals[payload]
    },
    setToken(state, payload){
      console.log("setToken", payload);
      state.token = payload;
    }
  },
  actions: {
    getContactToken({commit}, payload){
      console.log("getting token...");
      axios.post("/token.php", {
        action: "getToken",
      }).then((response) => {
        console.log("response", response);
        if (response.data.success) {
          commit("setToken", response.data.token);
        }
      }).catch((error) => {
        console.log("error", error);
      });
      },
  },
  modules: {},
});
