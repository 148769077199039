<template>
     <nav>
      <router-link to="/home">home</router-link>
      <router-link to="/about">about</router-link>
      <!-- <router-link to="/project">project</router-link> -->
      <div class="nav-item clickable" @click="toggleForm">contact us</div>
    </nav>
</template>

<script>
export default {
  name: "Nav",
  methods: {
    toggleForm() {
      this.$store.commit("toggleModal", "contact");
    },
  },
};
</script>

<style scoped lang="scss">
nav{
  padding: 20px;
  display: flex;
  justify-content: center;

}
nav a{
  color: #002e46;
  text-decoration: none;
  font-weight: 300;
  font-size: 3rem;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
  text-rendering: optimizeLegibility !important;
}
nav a.router-link-exact-active{
  color: #002e46;
  // text-decoration: underline;
}

nav a + a{
  margin-left: 60px;
}

.nav-item{
  margin-left: 60px;
  color: #002e46;
  text-decoration: none;
  font-weight: 300;
  font-size: 3rem;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
  text-rendering: optimizeLegibility !important;
}

//on mobile collapse all to burger menu
@media (max-width: 768px) {
  nav{
    flex-direction: column;
    text-align: center;
  }
  nav a + a{
    margin-left: 0;
    margin-top: 20px;
  }
  .nav-item{
    margin-left: 0;
    margin-top: 20px;
    text-align: center;
  }
  
}

</style>
